import { CUSTOM_ELEMENTS_SCHEMA, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataTableComponent } from './core/components/gestao/datatable/datatable.component';
import { RequerimentoListComponent } from './core/components/requerimento-list/requerimento-list/requerimento-list.component';
import { RequerimentoService } from './core/services/requerimento.service';
//import { FilterComponent } from './core/components/gestao/filter/filter.component';
import { MatBadgeModule } from '@angular/material/badge';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { GestaoComponent } from './core/components/gestao/gestao.component';
import { MenuHorizontalComponent } from './shared/components/menu-horizontal/menu-horizontal.component';
//import { HomeComponent } from './shared/components/home/home.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AjudaComponent } from './core/components/ajuda/ajuda.component';


import { CdkAccordionModule } from '@angular/cdk/accordion';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RecaptchaModule } from 'ng-recaptcha';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMaskModule } from 'ngx-mask';
import { AdminComponent } from './core/components/admin/admin.component';
import { CamposFormComponent } from './core/components/campos-form/campos-form.component';
import { CommonFunctionalityComponent } from './core/components/common-functionality/common-functionality.component';
import { ConsultaRequerimentoComponent } from './core/components/consulta-requerimento/consulta-requerimento.component';
import { DialogConfirmacaoComponent } from './core/components/gestao/datatable/dialogs/dialog-confirmacao/dialog-confirmacao.component';
import { DialogEdicaoComponent } from './core/components/gestao/datatable/dialogs/dialog-edicao/dialog-edicao.component';
import { DialogValidacaoComponent } from './core/components/gestao/datatable/dialogs/dialog-validacao/dialog-validacao/dialog-validacao.component';
import { HomeComponent } from './core/components/home/home.component';
import { InicioExternoComponent } from './core/components/inicio-externo/inicio-externo.component';
import { InicioComponent } from './core/components/inicio/inicio.component';
import { LoginComponent } from './core/components/login/login.component';
import { PerfilComponent } from './core/components/perfil/perfil.component';
import { AberturaRequerimentoRefComponent } from './core/components/requerimento/abertura/abertura.component';
import { ConclusaoComponent } from './core/components/requerimento/abertura/conclusao/conclusao.component';
import { InicioAberturaRequerimentoComponent } from './core/components/requerimento/abertura/inicio/inicio.component';
import { StepConclusaoComponent } from './core/components/requerimento/abertura/stepper/step-conclusao/step-conclusao.component';
import { CampoComponent } from './core/components/requerimento/abertura/stepper/step/grupo/campo/campo.component';
import { ChamarGrupoComponent } from './core/components/requerimento/abertura/stepper/step/grupo/campo/chamar-grupo/chamar-grupo.component';
import { FileComponent } from './core/components/requerimento/abertura/stepper/step/grupo/campo/file/file.component';
import { CampoMultiValoradoComponent } from './core/components/requerimento/abertura/stepper/step/grupo/campo/multi-valorado/multi-valorado.component';
import { PeriodCalendarComponent } from './core/components/requerimento/abertura/stepper/step/grupo/campo/period-calendar/period-calendar.component';
import { GrupoComponent } from './core/components/requerimento/abertura/stepper/step/grupo/grupo.component';
import { GrupoMultiValoradoComponent } from './core/components/requerimento/abertura/stepper/step/grupo/multi-valorado/multi-valorado.component';
import { StepComponent } from './core/components/requerimento/abertura/stepper/step/step.component';
import { StepperAberturaRequerimentoComponent } from './core/components/requerimento/abertura/stepper/stepper.component';
import { ConfirmationDialogComponent } from './core/components/requerimento/abertura/utils/confirmation-dialog/confirmation-dialog.component';
import { RequerimentoComponent } from './core/components/requerimento/requerimento.component';
import { ReenvioTokenComponent } from './core/components/token/reenvio-token/reenvio-token.component';
import { AuthenticationAppInterceptor } from './core/interceptors/authentication-app.interceptor';
import { ErrorInteceptor } from './core/interceptors/error.interceptor';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { CustomFormModule } from './core/modules/custom-form/custom-form.module';
import { AberturaRequerimentoComponent } from './core/pages/requerimentos/abertura-requerimento/abertura-requerimento.component';
import { FilesRequerimentoComponent } from './core/pages/requerimentos/abertura-requerimento/files-requerimento/files-requerimento/files-requerimento.component';
import { ConsultaExterna } from './core/pages/requerimentos/consulta-requerimento-externa/index/consulta-externa.component';
import { IndexComponent } from './core/pages/requerimentos/index/index.component';
import { AcessoExternoService } from './core/services/acesso-externo.service';
import { ServidorService } from './core/services/servidor.service';
import { StatusService } from './core/services/status.service';
import { TipoRequerimentoService } from './core/services/tipo.requerimento.service';
import { TokenService } from './core/services/token.service';
import { DashComponent } from './layouts/dash/dash.component';
import { DialogLoadingComponent } from './shared/components/dialog-loading/dialog-loading.component';
import { RequerimentoAcessoExternoComponent } from './core/components/requerimento/acesso-externo/requerimento-acesso-externo.component';
import { AcessoNegadoComponent } from './core/components/acesso-negado/acesso-negado.component';
import { ErroComponent } from './core/components/erro/erro.component';


registerLocaleData(localePt, 'pt');

export let AppInjector: Injector;

export const MY_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    RequerimentoListComponent,
    DataTableComponent,
    //FilterComponent,
    GestaoComponent,
    MenuHorizontalComponent,
    HomeComponent,
    AjudaComponent,
    DialogConfirmacaoComponent,
    DialogValidacaoComponent,
    AdminComponent,
    LoginComponent,
    DialogLoadingComponent,
    DashComponent,
    InicioComponent,
    AberturaRequerimentoComponent,
    ConsultaRequerimentoComponent,
    IndexComponent,
    ReenvioTokenComponent,
    CamposFormComponent,
    RequerimentoComponent,
    DialogEdicaoComponent,
    FilesRequerimentoComponent,
    InicioExternoComponent,
    AberturaRequerimentoComponent,
    AberturaRequerimentoRefComponent,
    InicioAberturaRequerimentoComponent,
    StepperAberturaRequerimentoComponent,
    ConsultaExterna,
    PerfilComponent,
    CommonFunctionalityComponent,
    StepComponent,
    GrupoComponent,
    CampoComponent,
    CampoMultiValoradoComponent,
    GrupoMultiValoradoComponent,
    FileComponent,
    ChamarGrupoComponent,
    StepConclusaoComponent,
    ConfirmationDialogComponent,
    ConclusaoComponent,
    PeriodCalendarComponent,
    RequerimentoAcessoExternoComponent,
    AcessoNegadoComponent,
    ErroComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatSortModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    NgxMaskModule.forRoot(),
    MatProgressSpinnerModule,
    NgxSpinnerModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatMomentDateModule,
    PdfViewerModule,
    CdkAccordionModule,
    MatStepperModule,
    MatTooltipModule,
    MatExpansionModule,
    RecaptchaModule,
    CustomFormModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    RequerimentoService, TipoRequerimentoService, ServidorService, TokenService, MatDialog, DataTableComponent, MatPaginator, StatusService, AcessoExternoService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationAppInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInteceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: MAT_DATE_LOCALE, useValue: 'pt' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }

  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}

