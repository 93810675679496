export const environment = {
    production: false,
    name: 'development environment',
    code: 1001,
    apiUrlUsuario: 'https://apiusuario.dev.sgp.uerj.br/api',
    apiUrlRequerimento: 'https://apirequerimento.dev.sgp.uerj.br/api',
    apiUrlFile: 'https://apifile.dev.sgp.uerj.br/api',
    apiUrlFormulario: 'https://apiformulario.dev.sgp.uerj.br/api',
    apiUrlServidor: 'https://apiservidores.dev.sgp.uerj.br/api',
    apiUrlEmail: 'https://apiemail.dev.sgp.uerj.br/api',
    secretKey: '29dc5d19-2a91-4cca-8721-3fa1bf8cfc8b',
    domainApi: 'dev.sgp.uerj.br/api/',
    domainApp: 'https://sisre.dev.sgp.uerj.br/'
};
