<div class="container" id="areaBusca">
    <div class="card shadow">
        <div class="card-body">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <strong>Requerimento:</strong>
                                Valor do Requerimento
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <strong>Tipo:</strong>
                                {{ requerimento.tipoRequerimento.tipoRequerimentoPai?.nome ?
                                requerimento.tipoRequerimento.tipoRequerimentoPai.nome + ' / ' : '' }}{{
                                requerimento.tipoRequerimento?.nome }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <strong>Protocolo:</strong>
                                {{ requerimento.numeroProtocolo }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <strong>Número SEI:</strong>
                                {{ requerimento.numeroSEI }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <strong>Requerente:</strong>
                                {{ requerimento.cpfRequerente }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <strong>Status:</strong>
                                {{ requerimento.status.descricao }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <strong>Data de abertura:</strong>
                                {{ requerimento.dataAbertura | date: 'dd/MM/yyyy hh:mm:ss' }}
                            </div>
                        </div>
                        <!--<div class="row">
                <div class="col-md-12">
                  <strong>URL da consulta pública no SEI:</strong>
                  Valor da URL da Consulta Pública no SEI
                </div>
              </div>-->
                    </div>
                    <div>
                    </div>

                    <br>

                    <div id="listagemRequerimentos">
                        <table class="table ">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Descrição</th>
                                    <th>Data</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let historico of dataSource">
                                    <td>{{ historico.statusRequerimento.descricao }}</td>
                                    <td>{{ historico.observacao }}</td>
                                    <td>{{ historico.dataOperacao | date: 'dd/MM/yyyy hh:mm:ss' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="text-align: right;">
                        <a *ngIf="!logado" [routerLink]="'/'"><i class="bi bi-arrow-left"></i> Início
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>