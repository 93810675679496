import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaginacaoDTO } from '../models/domain/paginacao-dto.model';
import { Requerimento } from '../models/domain/requerimento-class.model';

@Injectable()
export class RequerimentoService {
  private requerimentoUrl: string;
  private matricula: string;

  constructor(private http: HttpClient) {
    this.requerimentoUrl = environment.apiUrlRequerimento + '/requerimento/';
    //this.requerimentoUrl = 'http://localhost:8085/api/requerimento/';
  }

  public findAll(): Observable<Requerimento[]> {
    return this.http.get<Requerimento[]>(this.requerimentoUrl + 'all');
  }

  public findAllPaginate(form: any): Observable<PaginacaoDTO<Requerimento>[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<PaginacaoDTO<Requerimento>[]>(this.requerimentoUrl + 'allPaginado', JSON.stringify(form), { headers: headers });
  }

  public findByNumeroProtocolo(numeroProtocolo: string): Observable<Requerimento[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<Requerimento[]>(this.requerimentoUrl + 'requerimentos', JSON.stringify({ numeroProtocolo: numeroProtocolo }), { headers: headers });
  }

  public abertura(form: any): Observable<Requerimento> {
    return this.http.post<Requerimento>(this.requerimentoUrl + 'abertura', form);
  }

  public close(id: string, data: any): Observable<Requerimento[]> {
    return this.http.get<Requerimento[]>(this.requerimentoUrl + 'close/' + id);
  }

  public get(id: string): Observable<Requerimento> {
    return this.http.get<Requerimento>(this.requerimentoUrl + id);
  }

  public findByProtocolo(numeroProtocolo: string): Observable<Requerimento> {
    return this.http.get<Requerimento>(this.requerimentoUrl + 'protocolo/' + numeroProtocolo);
  }

  public search(data: any): Observable<Requerimento[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<Requerimento[]>(this.requerimentoUrl + 'requerimentos', JSON.stringify(data), { headers: headers });
  }

  public validate(data: any): Observable<Requerimento[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<Requerimento[]>(this.requerimentoUrl + 'validate', JSON.stringify(data), { headers: headers });
  }

  public cancel(data: any): Observable<Requerimento[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<Requerimento[]>(this.requerimentoUrl + 'cancel', JSON.stringify(data), { headers: headers });
  }

  public update(data: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(this.requerimentoUrl + 'update/numeroSEI', JSON.stringify(data), { headers: headers });
  }

}
