import { AfterViewInit, Component, ElementRef, EmbeddedViewRef, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Observable, catchError, map, of } from 'rxjs';
import { UserResponse } from 'src/app/core/models/authentication/responses/user-response';
import { Grupo } from 'src/app/core/models/domain/Grupo';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { RequerimentoService } from 'src/app/core/services/requerimento.service';
import { ServidorService } from 'src/app/core/services/servidor.service';
import { TipoRequerimentoService } from 'src/app/core/services/tipo.requerimento.service';
//import { printJS } from 'print-js';
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import * as printJS from 'print-js';
import { AuthenticationHelper } from 'src/app/core/helpers/authentication.helper';
import { PapelResponse } from 'src/app/core/models/authentication/responses/papel-response';
import { AcessoExterno } from 'src/app/core/models/domain/AcessoExterno';
import { Campo } from 'src/app/core/models/domain/Campo';
import { RequerimentoFile } from 'src/app/core/models/domain/requerimento-file';
import { TipoRequerimento } from 'src/app/core/models/domain/TipoRequerimento';
import { AcessoExternoService } from 'src/app/core/services/acesso-externo.service';
import { FileService } from 'src/app/core/services/file.service';
import Swal from 'sweetalert2';
import { FilesRequerimentoComponent } from './files-requerimento/files-requerimento/files-requerimento.component';

@Component({
  selector: 'app-abertura-requerimento',
  templateUrl: './abertura-requerimento.component.html',
  styleUrls: ['./abertura-requerimento.component.css']
})
export class AberturaRequerimentoComponent implements OnInit, AfterViewInit {


  filterJsonFormRequerimento: FormGroup;
  jsonRequerimentoPassos: any[];
  papeisUsuario: Number[];
  listaRequerimentos: TipoRequerimento[];
  filesToUpload: RequerimentoFile[]
  listaRequerimentosFilhos: TipoRequerimento[];
  listaOperacoes: any;
  temOperacoes: boolean;
  temFilhos: boolean;
  operacaoSelecionada: Number;
  tipoRequerimentoSelecionado: TipoRequerimento;
  requerimentoaberto: boolean;
  userResponse?: UserResponse | null;
  formRequerimento: FormGroup;
  requerimento: any;
  passoAtivo: number;
  grupos: Array<Grupo>;

  @ViewChild('conclusao') conclusao: ElementRef;
  @ViewChild('dadosServidor') dadospinnersServidor: ElementRef;
  isPrimeiroPasso: boolean = true;
  tipoRequerimentoSelecionadoNome: string;
  tipoRequerimentoSelecionadoNomeFilho: string;

  viewContainerRef: ViewContainerRef;
  indexNextFiles: number = 1;

  authHelper: AuthenticationHelper;

  matriculaSelecionada: any;

  papeis: PapelResponse[] = [];
  papelSelecionado: any;

  gruposDadosBasicos = ['dados_funcionais', 'padrao_Pessoa', 'padrao_Servidor'];
  gruposDadosContatos = ['dados_contato', 'padrao_Pessoa_contato'];
  logado: boolean;

  moment: any = moment;

  constructor(
    private fbJsonFormRequerimento: FormBuilder,
    private formBuilder: FormBuilder,
    private tipoRequerimentoService: TipoRequerimentoService,
    private servidorService: ServidorService,
    private acessoExternoService: AcessoExternoService,
    private requerimentoService: RequerimentoService,
    private fileService: FileService,
    private auth: AuthenticationService,
    private aHelper: AuthenticationHelper,
    @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private el: ElementRef,
    private cd: ChangeDetectorRef
  ) {
    this.filterJsonFormRequerimento = this.fbJsonFormRequerimento.group({
    });
    this.formRequerimento = this.formBuilder.group({
      matricula: new FormControl('', Validators.required),
      operacao: new FormControl('', Validators.required),
      requerimento: new FormControl('', Validators.required),
      requerimentoFilho: new FormControl('')
    });
    this.authHelper = aHelper;
    this.auth.userResponse.subscribe(x => this.userResponse = x);
    this.filesToUpload = [];
    this.viewContainerRef = viewContainerRef;
    this.papeis = this.getPapeis();
    this.logado = this.authHelper.logado();
  }

  getPapeis(): Array<PapelResponse> {

    const papeisUsuario = this.authHelper.getPapeis();
    let papeis = new Array<PapelResponse>;

    papeisUsuario?.forEach(p => {

      if (!papeis.find((papel: PapelResponse) => ((!papel.matricula) && papel.nome_papel == p.nome_papel))) {
        papeis.push(p);
      }

    });

    return papeis;
  }

  getKeyForm(operacao: any, passo: any, grupo: any, campo: any): String {
    return this.getKeyCampoForm(operacao, passo, grupo, campo) + '.valor';
  }

  getKeyCampoForm(operacao: any, passo: any, grupo: any, campo: any): String {
    return this.getKeyArrayCamposForm(operacao, passo, grupo, campo) + '.' + campo.nome;
  }

  getKeyArrayCamposForm(operacao: any, passo: any, grupo: any, campo: any): String {
    return 'stepper.operacoes.' + operacao.nome + '.passos.' + passo.stepNumber + '.grupos.' + grupo.nome + '.campos';
  }

  getValueVisualizar(operacao: any, passo: any, grupo: any, campo: any): String {
    let nome = 'stepper.operacoes.' + operacao.nome + '.passos.' + passo.stepNumber + '.grupos.' + grupo.nome + '.campos.' + campo.nome + '.valor'
    let valor = this.filterJsonFormRequerimento.get(nome.toString())?.value
    return valor
  }

  getDadosBasicosContato(operacao: any, passo: any, grupos: string[], campo: any): String {

    let valor;
    let index = 0;

    while (!valor) {
      let nome = 'stepper.operacoes.' + operacao.nome + '.passos.' + passo.stepNumber + '.grupos.' + grupos[index] + '.campos.' + campo.nome + '.valor';
      valor = this.filterJsonFormRequerimento.get(nome.toString())?.value;
      index++;
    }

    return valor;
  }

  proximoTab(passoIndex: any, operacaoStep: any, passo: any, orientation: any) {

    this.filterJsonFormRequerimento.markAllAsTouched();

    let form = this.filterJsonFormRequerimento.controls.stepper.get('operacoes').get(this.getNomeOperacaoSelecionada()).get('passos').get((passo.stepNumber).toString()).get('grupos') as FormGroup;

    if (orientation == 'forward') {

      if (form.valid) {

        this.isPrimeiroPasso = passoIndex == 0;
        this.navegarPeloTab(operacaoStep, passoIndex);

      } else {

        this.isPrimeiroPasso = passoIndex - 1 == 0;
        this.markFormGroupTouched(form as FormGroup);
        this.focusOnError(form);
      }

    } else if (orientation == 'backward') {
      this.isPrimeiroPasso = false;
      this.navegarPeloTab(operacaoStep, passoIndex);
      //this.markFormGroupTouched(form as FormGroup);
    }

  }

  navegarPeloTab(operacaoStep: any, passoIndex: any) {
    try {

      let elementId = "v-requerimento-" + operacaoStep.id + "-" + operacaoStep.steps[passoIndex].nome + "-tab"
      let element: HTMLElement = document.getElementById(elementId) as HTMLElement;
      element.click();
    }
    catch (e) {
      this.irParaConclusao()
    }
  }

  ngOnInit(): void {

    //const decodedToken = atob(localStorage.token);
    //console.log("TOKEN: "+decodedToken)
    //define um array de papeis
    /*this.papeisUsuario = this.userResponse?.papeis.map(grupo => {
      return Number(grupo.id);
    })*/
    //lista os requerimentos de acordo com os papeis do usuário
    if (!this.logado) {
      this.pesquisaRequerimentoPorPapeisUsuario(null);
    }
    //o json que represenha o formulário dinâmico
    //this.filterJsonFormRequerimento = this.generateFilterForStteper();
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit');

  }

  //caso precise pegar a entrada de algum campo ou de todos os que estão sendo preenchidos
  onKey(event: any) {
    let data = event.target.value + ' | ';
  }

  //gerador de campos do formulário e agrupamentos
  generateFilterForStteper(): FormGroup {
    const baseForm = this.fbJsonFormRequerimento.group({});
    this.jsonRequerimentoPassos.forEach(form => {
      baseForm.addControl("stepper", this.generateFormGroupStepper(baseForm, form));
    });
    return baseForm;
  }

  //agrupamento dos campos de acordo com a chave
  generateFormGroupStepper(baseForm: any, form: any): FormGroup | FormControl {

    //monta as operações
    if (form?.stepper?.operacoes) {
      const operacoesForm = this.fbJsonFormRequerimento.group({});
      let operacoes = form?.stepper?.operacoes
      const formGroup = this.fbJsonFormRequerimento.group({});
      operacoes.forEach((operacao: any) => {
        formGroup.addControl(operacao.nome, this.generateFormGroupStepper(formGroup, operacao));
      });
      operacoesForm.addControl("requerimento", this.formRequerimento);
      operacoesForm.addControl("orientation", new FormControl("forward"));
      operacoesForm.addControl("protocolo", new FormControl("protocolo"));
      operacoesForm.addControl("titulo", new FormControl("titulo"));
      operacoesForm.addControl("ccfg", new FormControl("ccfg"));
      operacoesForm.addControl("servidor", new FormControl("servidor"));
      operacoesForm.addControl("operacao", new FormControl("operacao"));
      operacoesForm.addControl("operacoes", formGroup);
      return operacoesForm;
    }

    //monta os passos das operações
    if (form?.steps) {
      let steps = form?.steps
      const formPassosGroup = this.fbJsonFormRequerimento.group({});
      const formGroup = this.fbJsonFormRequerimento.group({});
      steps.forEach((step: any) => {
        formGroup.addControl(step.stepNumber, this.generateFormGroupStepper(formGroup, step));
      });
      formPassosGroup.addControl("passos", formGroup);
      return formPassosGroup;
    }

    //monta a lista de grupos
    if (form?.grupos) {
      let grupos = form?.grupos
      const grupoForm = this.fbJsonFormRequerimento.group({});
      const gruposForm = this.fbJsonFormRequerimento.group({});
      grupos.forEach((grupo: any) => {
        grupoForm.addControl(grupo.nome, this.generateFormGroupStepper(gruposForm, grupo))
      });
      gruposForm.addControl("grupos", grupoForm)
      return gruposForm;
    }

    //monta os campos dos grupos
    if (form?.campos) {
      let grupo = form
      let campos = form?.campos
      const grupoForm = this.fbJsonFormRequerimento.group({});
      grupoForm.addControl("id", new FormControl(grupo.id))
      grupoForm.addControl("nome", new FormControl(grupo.nome))
      grupoForm.addControl("label", new FormControl(grupo.label))
      grupoForm.addControl("visivel", new FormControl(grupo.visivel))
      grupoForm.addControl("grupoPai", new FormControl(grupo.grupoPai))
      grupoForm.addControl("campos", this.generateFormGroupStepper(grupo, campos))
      grupoForm.addControl("tipoLayout", new FormControl(grupo.tipoLayout))

      return grupoForm;
    }

    //monta a chave de cada campo do grupo
    if (form.length > 0) {
      let campos = form
      const camposForm = this.fbJsonFormRequerimento.group({});
      campos.forEach((campo: any) => {
        if (campo?.id && campo?.nome && campo?.tipo) {
          camposForm.addControl(campo.nome, this.generateFormGroupStepper(baseForm, campo))
        }
      });
      return camposForm
    }

    //monta os fiels dos campos
    if (form?.id && form?.nome && form?.tipo) {
      let campo = form
      const campoForm = this.fbJsonFormRequerimento.group({});

      campoForm.addControl('id', new FormControl(campo.id))
      campoForm.addControl('nome', new FormControl(campo.nome))
      campoForm.addControl('label', new FormControl(campo.label))
      campoForm.addControl('tipo', new FormControl(campo.tipoComponente))
      campoForm.addControl('tipo', new FormControl(campo.multiCampo))
      campoForm.addControl('obrigatorio', new FormControl(campo.obrigatorio))
      campoForm.addControl('grupo', new FormControl(campo.grupo))
      campoForm.addControl('visivel', new FormControl(campo.visivel))

      if (campo?.tipoComponente === 'calendar') {
        setTimeout(() => {
          $("#" + campo.nome).mask('99/99/9999');
        }, 0);
      }

      if (campo?.tipo === 'url') {
        campoForm.addControl('valor', new FormControl(campo.opcoesCampo[0].valor))
      } else {
        campoForm.addControl('valor', new FormControl('', this.getValidator(campo)))

        if (baseForm.visivel) {
          campoForm.enable();
        } else {
          campoForm.disable();
        }
      }
      return campoForm;
    }

    return new FormControl("");
  }

  //gerador recursivo de exemplo, para outro formulário
  generateFilterForm(): FormGroup {
    const baseForm = this.fbJsonFormRequerimento.group({});
    this.jsonRequerimentoPassos.forEach(field => {
      baseForm.addControl(field.key, this.generateFormGroup(baseForm, field));
    });
    return baseForm;
  }

  generateFormGroup(baseForm: FormGroup, field: { group: any[]; }): FormGroup | FormControl {
    if (field.group) {
      const formGroup = this.fbJsonFormRequerimento.group({});
      field.group.forEach(item => {
        formGroup.addControl(item.key, this.generateFormGroup(formGroup, item));
      });
      return formGroup;
    }
    return new FormControl("");
  }

  passoSelecionado(passo: String) {
    this.passoAtivo = Number(passo)
    if (this.passoAtivo == 99) {
      this.disableCamposPreenchidos()
    }
  }

  passo(passo: number) {
    this.passoAtivo = passo
  }

  irParaConclusao() {
    this.disableCamposPreenchidos()
    this.conclusao.nativeElement.click();
  }

  disableCamposPreenchidos() {
    let elements: HTMLCollectionOf<Element> = document.getElementsByClassName("visualizar") as HTMLCollectionOf<Element>;
    Array.from(elements).forEach(function (item) {
      let campo = item as HTMLElement
      campo.setAttribute('disabled', 'disabled')
    });
  }


  salvar() {
    if ((this.operacaoSelecionada != null || this.operacaoSelecionada != undefined) && (
      (Number(this.operacaoSelecionada) == 1 && this.filterJsonFormRequerimento.controls.stepper.get('operacoes').get('Solicitação').valid)
      || (Number(this.operacaoSelecionada) == 2 && this.filterJsonFormRequerimento.controls.stepper.get('operacoes').get('Cancelamento').valid))) {
      this.abrirRequerimento(Number(this.operacaoSelecionada));
    } else {
      //alert('um ou mais campos do formulário não foram preenchidos corretamente')
      Swal.fire(
        'Erro ao salvar',
        'Um ou mais campos do formulário não foram preenchidos corretamente',
        'error'
      )
    }
  }

  private abrirRequerimento(operacaoSelecionada: Number) {

    let cpfRequerente;
    let cpfRequisitado;
    let cpfAtendente;
    let nomeRequerente = null;
    let emailRequerente = null;
    let statusDto = { "id": 1 }
    let tipoRequerimentoDto = { "id": Number(this.formRequerimento.controls.requerimento.value) }

    if (operacaoSelecionada) {

      let tipoOperacao = { nome: (!this.operacaoSelecionada || Number(this.operacaoSelecionada) == 1) ? 'Solicitação' : 'Cancelamento' };

      const valueCpf = this.getDadosBasicosContato(tipoOperacao, { stepNumber: 2 }, this.gruposDadosBasicos, { nome: 'cpf' });

      cpfRequerente = valueCpf;
      cpfRequisitado = valueCpf;
      cpfAtendente = valueCpf;

      nomeRequerente = this.getDadosBasicosContato(tipoOperacao, { stepNumber: 2 }, this.gruposDadosBasicos, { nome: 'nome' });
      emailRequerente = this.getDadosBasicosContato(tipoOperacao, { stepNumber: 2 }, this.gruposDadosContatos, { nome: "e_mail" })

    }

    ////this.fileService.gerarPDF(this.filterJsonFormRequerimento);
    this.requerimentoService.abertura({
      "cpfRequerente": cpfRequerente,
      "cpfRequisitado": cpfRequisitado,
      "cpfAtendente": cpfAtendente,
      "statusDto": statusDto,
      "tipoRequerimentoDto": tipoRequerimentoDto,
      "nomeRequerente": nomeRequerente,
      "emailRequerente": emailRequerente,
      "matriculaRequisitado": this.matriculaSelecionada
    }).subscribe(data => {
      this.requerimentoaberto = true;
      this.requerimento = data;

      this.filterJsonFormRequerimento.get('stepper.protocolo')?.setValue(data.numeroProtocolo);

      Swal.fire(
        'Parabéns!!',
        'Requerimento criado com sucesso',
        'success'
      )

      let requerimento = null;


      let dadosAcessoExterno = new AcessoExterno(
        this.setExpiracao(),
        data.emailRequerente,
        requerimento,
        this.generateRandomToken()
      );

      this.acessoExternoService.save(dadosAcessoExterno).subscribe(data => { })

      this.filterJsonFormRequerimento.get('stepper.titulo').setValue(this.getTitulo());

      if (operacaoSelecionada) {
        this.filterJsonFormRequerimento.get('stepper.operacao').setValue(this.operacaoSelecionada);
      }

      this.fileService.gerarRequerimentoPDF(this.filterJsonFormRequerimento.getRawValue(), this.filesToUpload).subscribe(
        data => {
          console.log('Criado Requerimento' + data.numeroProtocolo);
        }
      );
    });

  }

  generateRandomToken(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const tokenLength = 150;
    let token = '';

    for (let i = 0; i < tokenLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters.charAt(randomIndex);
    }

    return token;
  }



  setExpiracao(): Date {
    const hoje = new Date();
    const expiracao = new Date();
    expiracao.setDate(hoje.getDate() + 60);
    return expiracao;

  }



  printPDF() {
    let DATA: any = document.getElementById('formResumo');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      //let fileWidth = 208;
      //let fileHeight = (canvas.height * fileWidth) / canvas.width;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('print-demo.pdf');
    });
  }

  downloadPdf() {
    printJS('formResumo', 'html');
  }

  pesquisaRequerimentoPorPapeisUsuario(papeis: Number[]) {
    this.tipoRequerimentoService.findAllPorPapeisUsuario(papeis, papeis ? true : false).subscribe(data => {
      this.listaRequerimentos = data
    });
  }

  pesquisaRequerimentoPorTipoOperacao(id: number) {
    this.tipoRequerimentoService.findAllPorTipoOperacao({
      id: id,
    }).subscribe(data => {
      this.listaRequerimentos = data
    });
  }

  // Popula os dados de Servidor
  consultarDadosServidor(userResponse: UserResponse) {
    if (userResponse != null) {
      this.servidorService.findByMatricula(this.matriculaSelecionada).subscribe({
        next: (data) => {

          this.popularCamposBasicos(data);

          console.log('Dados preenchidos automaticamente.');
          console.log(data);

        },
        error: (err) => {
          console.log(['err'], err);
        }
      });
    }
  }

  possuiPis(userResponse: UserResponse): Observable<boolean> {
    if (userResponse != null) {
      return this.servidorService.findByMatricula(this.matriculaSelecionada).pipe(
        map(data => data.pessoa != null),
        catchError(error => {
          console.error('Erro ao buscar dados do servidor:', error);
          return of(false); // Retornar false em caso de erro
        })
      );
    } else {
      return of(false); // Se userResponse for null, retorna false imediatamente
    }
  }



  popularCamposBasicos(data: any) {

    let tipoOperacao = { nome: (!this.operacaoSelecionada || Number(this.operacaoSelecionada) == 1) ? 'Solicitação' : 'Cancelamento' };
    let passo = { stepNumber: 2 };

    let grupo = { nome: ['dados_funcionais', 'padrao_Servidor', 'padrao_Pessoa'] };

    // Dados Funcionais
    this.preencherValorDoCampo('nome', data.nome, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('pis', data.pessoa.pis, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('cpf', (Number(data.pessoa.cpf).toString().length < 11 ? "0".concat(data.pessoa.cpf) : data.pessoa.cpf), tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('id_funcional', data.idFuncional, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('localizacao', data.localizacao?.sigla, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('lotacao', data.lotacao?.sigla, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('matricula', data.matricula, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('perfil', data.cargo?.nomeCargo, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('vinculo', data.vinculo, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('cargo_funcao', data.perfil?.nome, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('cargo', data.perfil?.nome, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('especialidade', data.especialidade?.nomeEspecialidade, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('carga_horaria_remuneratoria', data.cargaHoraria, tipoOperacao, passo, grupo);
    this.preencherValidarValorDoCampo('servidor', (data.matricula != null ? true : false), tipoOperacao, passo, grupo, false);
    this.preencherValidarValorDoCampo('possui_ccfg', data.possuiCCFG, tipoOperacao, passo, grupo, false);

    this.filterJsonFormRequerimento.get('stepper').get('ccfg').setValue(data.possuiCCFG);
    this.filterJsonFormRequerimento.get('stepper').get('servidor').setValue(data.matricula != null ? true : false);

    grupo = { nome: ['dados_endereco', 'padrao_Pessoa_endereco'] };

    // Dados de Endereço
    this.preencherValorDoCampo('logradouro', data.pessoa?.endereco, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('logradouro_numero', data.pessoa?.numero, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('complemento', data.pessoa?.complemento, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('cep', data.pessoa?.cep, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('cidade', data.pessoa?.municipio?.nome, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('bairro', data.pessoa?.bairro, tipoOperacao, passo, grupo);

    // Dados de Contato
    /*this.preencherValorDoCampo('celular',data.pessoa?.celular, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('e_mail',data.pessoa?.email, tipoOperacao, passo, grupo);
    this.preencherValorDoCampo('telefone',data.pessoa?.telefone, tipoOperacao, passo, grupo);*/

  }

  // Método que valida ou não se o valor do Campo não está vazio.
  // Usar esse método principalmente para campos do tipo checkbox, pois pode-se passar o parâmetro checkValid
  preencherValidarValorDoCampo(nomeCampo: string, valorCampo: any, tipoOperacao: any, passo: any, grupo: any, checkValid: boolean) {

    if (!checkValid || valorCampo) {
      const field = this.getKeyForm(tipoOperacao, passo, grupo, { nome: nomeCampo });
      this.filterJsonFormRequerimento.get(field?.toString())?.setValue(valorCampo);
      this.filterJsonFormRequerimento.get(field?.toString())?.disable();
    }

  }

  preencherValorDoCampo(nomeCampo: string, valorCampo: any, tipoOperacao: any, passo: any, grupo: any) {
    grupo.nome.forEach((g: string) => {
      this.preencherValidarValorDoCampo(nomeCampo, valorCampo, tipoOperacao, passo, { nome: g }, true);
    });
  }

  montaPassosPreenchimentoTipoRequerimentoSelecionado(id: number) {
    this.tipoRequerimentoService.findStepperTipoRequrimento({
      id: id
    }).subscribe(data => {
      this.jsonRequerimentoPassos = [data];

      console.log(data);

      let possuiPis: boolean;

      this.possuiPis(this.userResponse).subscribe(buscaPis => {
        possuiPis = buscaPis;

        const campos = this.jsonRequerimentoPassos?.[0]?.stepper?.operacoes?.[0]?.steps?.[0]?.grupos?.[0]?.campos;

        const campoPis = campos?.find((campo: any) => campo.nome === 'pis');

        // Se não encontrar o pis , remove-o do array
        if (!possuiPis) {
          const index = this.jsonRequerimentoPassos[0]['stepper']['operacoes'][0]['steps'][0]['grupos'][0]['campos'].indexOf(campoPis);
          if (index !== -1) {
            this.jsonRequerimentoPassos[0]['stepper']['operacoes'][0]['steps'][0]['grupos'][0]['campos'].splice(index, 1);
          }
        }

        if (campoPis)
          this.filterJsonFormRequerimento.controls.stepper.get('operacoes').get('Solicitação').get('passos').get('2').get('grupos').get('padrao_Pessoa').get('campos').get('pis').disable();
      });




      this.filterJsonFormRequerimento = this.generateFilterForStteper();
      this.listaOperacoes = this.jsonRequerimentoPassos[0].stepper.operacoes;
      if (this.listaOperacoes != undefined && this.listaOperacoes.length > 1) {
        this.temOperacoes = true;
        this.formRequerimento.get("operacao").setValue("");
        this.operacaoSelecionada = undefined;
      } else {
        this.temOperacoes = false;
        this.formRequerimento.get("operacao").setValue(this.jsonRequerimentoPassos[0].stepper.operacoes[0]?.id);
        this.operacaoSelecionada = this.formRequerimento.controls.operacao.value;
      }

      if (this.listaOperacoes?.length == 1 && this.tipoRequerimentoSelecionado?.tipoRequerimentoFilhos?.length == 0) {
        document.getElementById('titulorequerimento').style.display = 'none';
      }

      this.consultarDadosServidor(this.userResponse);


    });
  }



  getPattern(campo: any): any {
    /* if(campo.mascara ==null && campo.expressaoRegular !=null) {
      return campo.expressaoRegular
    } */
    return null
  }

  private getValidator(campo: any): ValidatorFn | ValidatorFn[] {
    let validacoes: any = [];
    let validador: ValidatorFn | ValidatorFn[];
    if (campo?.obrigatorio) {
      validacoes.push(Validators.required)
    }


    if (campo?.nome != null && campo?.nome == "e_mail") {
      validacoes.push(Validators.email)
    }
    if (campo?.mascara == null && campo?.expressaoRegular != null && campo?.nome != "e_mail") {
      validacoes.push(Validators.pattern(campo?.expressaoRegular))
    }
    if (campo?.tamanhoMaximo != null && campo?.tamanhoMaximo > 0) {
      validacoes.push(Validators.maxLength(Number(campo?.tamanhoMaximo)))
    }

    if (campo.camposfilhos != null) {
      campo.camposfilhos.forEach((campoFilho: any) => {
        const validadorCampoFilho = this.getValidator(campoFilho);

        if (validadorCampoFilho) {
          validacoes.push(Validators.required);
        }
      });

      //const algumCampoFilhoObrigatorio = campo.camposfilhos.some((campoFilho: any) => campoFilho.obrigatorio === true);

      //if (algumCampoFilhoObrigatorio) {
      //validacoes.push(Validators.required);
      //}
    }

    validador = Validators.compose(validacoes);
    return validador
  }

  changeOperacao(e: any) {

    this.formRequerimento.get("operacao").setValue(e.target.value);
    this.operacaoSelecionada = this.formRequerimento.controls.operacao.value;

    document.getElementById('titulorequerimento').style.display = 'none'

    this.consultarDadosServidor(this.userResponse);
  }

  changePapel(e: any) {

    this.papelSelecionado = e.target.selectedOptions[0].dataset.id;
    this.matriculaSelecionada = e.target.selectedOptions[0].dataset.matricula;

    const papeisUsuario = [];

    papeisUsuario.push(Number(this.papelSelecionado));

    //lista os requerimentos de acordo com os papeis do usuário
    this.pesquisaRequerimentoPorPapeisUsuario(papeisUsuario);

  }

  changeRequerimento(e: any) {
    //console.log(["changeRequerimento"], e.target.value);
    //console.log(["changeRequerimento"], this.formRequerimento.value);
    // console.log(["changeRequerimento"], this.formRequerimento.controls.requerimento.value);

    this.tipoRequerimentoSelecionadoNome = e.target.selectedOptions[0].textContent;

    this.tipoRequerimentoSelecionado = this.listaRequerimentos.filter(q => q.id == e.target.value)[0];

    if (this.tipoRequerimentoSelecionado != undefined && this.tipoRequerimentoSelecionado.tipoRequerimentoFilhos.length > 0) {
      this.temFilhos = true
      this.listaRequerimentosFilhos = this.tipoRequerimentoSelecionado.tipoRequerimentoFilhos.filter(item => item.visivel === true);

    } else {
      this.temFilhos = false
      this.listaRequerimentosFilhos = []

    }

    this.montaPassosPreenchimentoTipoRequerimentoSelecionado(e.target.value)
    this.filesToUpload = [];
  }

  changeRequerimentoFilho(e: any) {

    document.getElementById('titulorequerimento').style.display = 'none';
    document.getElementById('subgruporequerimento').style.display = 'none';

    this.tipoRequerimentoSelecionadoNomeFilho = e.target.selectedOptions[0].textContent;
    this.montaPassosPreenchimentoTipoRequerimentoSelecionado(e.target.value)
  }

  selecionarOutroRequerimento() {
    const prevConfiguration = this.router.routeReuseStrategy.shouldReuseRoute;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate(["./"], { relativeTo: this.activatedRoute }).then(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = prevConfiguration;
      this.router.onSameUrlNavigation = "ignore";
    });

  }

  /**
 * Marks all controls in a form group as touched
 * @param formGroup - The form group to touch
 */
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: FormGroup<any>) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  public addFileInput(form: any, operacao: any, passo: any, grupo: any, campo: any, selector: any) {

    let campoNome = this.getKeyArrayCamposForm({ nome: operacao.nome }, { stepNumber: passo.stepNumber }, { nome: grupo.nome }, { nome: campo.nome });
    const novoCampo = { id: campo.id + this.indexNextFiles.toString(), nome: campo.nome + this.indexNextFiles, label: campo.label, tipo: campo.tipo, valor: '', obrigatorio: campo.obrigatorio };

    let campos = (this.filterJsonFormRequerimento.get(campoNome.toString()) as FormGroup);

    //criar um novo registro dentro do FormArray
    campos.addControl(
      novoCampo.nome, new FormGroup({
        id: new FormControl(novoCampo.id, Validators.required),
        nome: new FormControl(novoCampo.nome, Validators.required),
        label: new FormControl(novoCampo.label, Validators.required),
        tipo: new FormControl(novoCampo.tipo, Validators.required),
        valor: new FormControl(novoCampo.valor, campo.obrigatorio ? Validators.required : null),
        idParent: new FormControl(campo.id/*, Validators.required*/)
      })
    );

    const component = this.viewContainerRef.createComponent(FilesRequerimentoComponent);

    component.instance.campo = novoCampo;
    component.instance.filesArray = this.filesToUpload;
    component.instance.parentFormGroup = campos;
    component.instance.formGroup = this.filterJsonFormRequerimento.get(this.getKeyCampoForm(operacao, passo, grupo, novoCampo).toString());
    component.instance.removivel = true;

    const domElem = (component.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    let nodeElement = document.getElementById("files_" + campo.nome);
    nodeElement.appendChild(domElem);

    this.indexNextFiles = this.indexNextFiles + 1;
    this.filterJsonFormRequerimento.updateValueAndValidity();

  }

  getTitulo() {

    let operacao = (this.operacaoSelecionada == 1 ? 'Solicitação' : 'Cancelamento');
    let tipoRequerimentoSelecionadoNomeFilho = this.tipoRequerimentoSelecionadoNomeFilho ? ' / ' + this.tipoRequerimentoSelecionadoNomeFilho : '';
    let tipoRequerimentoSelecionado = this.tipoRequerimentoSelecionado?.nome;

    return operacao + ' de ' + tipoRequerimentoSelecionado + tipoRequerimentoSelecionadoNomeFilho;
  }

  // Método onde é passado o form dos grupos e verifica os campos inválidos para colocar o focus
  private focusOnError(form: FormGroup) {

    if (!form.valid) {

      // Percorre os grupos
      for (const key of Object.keys(form.controls)) {

        // Verifica se o Grupo está invalidado
        if (form.get(key).invalid) {

          // Caso o Grupo esteja invalidado, intera os campos do grupo invalidado
          for (const keyCampo of Object.keys((form.get(key).get('campos') as FormGroup).controls)) {

            // Verifica se o campo atual está inválido
            if (form.get(key).get('campos').get(keyCampo).invalid) {

              // Pega o elemento nativo do campo pelo id e seta o focus no input do campo
              const invalidControl = this.el.nativeElement.querySelector('#' + form.get(key).get('campos').get(keyCampo).value.nome);

              let id = (invalidControl?.type == 'file') ? 'files_' + invalidControl.id : invalidControl?.id;

              if (id) {

                document.getElementById(id)
                  .scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });

                invalidControl.focus();

              }

              // Para quebrar o loop uma vez que foi satisfeita a interação
              break;
            }

          }

          // Break para quebrar o loop do parent
          break;
        }

      }

    }

  }

  // Método responsável por tornar visíveis os campos que estejam no grupo
  mostrarCamposFilhos(passo: any, grupoParam: any, campo: any, event: any) {

    const isChecked = event.target.checked;

    // Se o campo não chama um grupo retorna
    if (!campo.grupo) {
      return;
    }

    let operacao = (this.operacaoSelecionada == 1 ? 'Solicitação' : 'Cancelamento');

    // Pega os elementos html do grupo que foi chamado
    let element = this.el.nativeElement.querySelector('#grupo_' + campo.grupo.nome);
    let elementConclusao = this.el.nativeElement.querySelector('#grupo_' + campo.grupo.nome + '_conclusao');

    // Seleciona o objeto do grupo
    let grupo = this.filterJsonFormRequerimento.controls.stepper.get('operacoes').get(operacao).get('passos')
      .get((passo.stepNumber).toString()).get('grupos').get(campo.grupo.nome);

    // Atribui os estados para o objeto e pra página
    element.hidden = !isChecked;
    elementConclusao.hidden = !isChecked;
    grupo.get('visivel').setValue(!isChecked);

    this.ocultarLimparCamposFilhos(operacao, passo, grupo, element, isChecked);

  }

  // Método responsável por limpar os Campos e oculta-los
  ocultarLimparCamposFilhos(operacao: any, passo: any, grupo: any, element: any, isChecked: boolean) {

    // percorre os campos do grupo atual
    Object.values<Campo>(grupo.get('campos').getRawValue()).forEach((campo: any) => {

      const campoControl = this.filterJsonFormRequerimento.controls.stepper.get('operacoes').get(operacao)
        .get('passos').get((passo.stepNumber).toString()).get('grupos').get(grupo.get('nome').value).get('campos').get(campo.nome);

      // Se não estiver checado será necessário limpar os campos e esconde-los
      if (!isChecked) {

        let elementField = this.getElementQuerySelector('#', campo.nome);

        // Se for checkbox e chamar um outro grupo
        if (campo.tipo == 'checkbox' && campo.grupo) {

          element = this.getElementQuerySelector('#grupo_', campo.grupo.nome);

          grupo = this.getChamarGrupoByCampo(operacao, passo, campo);

          // Torna o grupo que a tag chama invisível, assim como os elementos pertinentes
          grupo?.get('visivel').setValue(false);

          elementField.checked = false;
          element.hidden = true;

          // Se o grupo possuir outros campos, usa-se a recursividade chamando o método novamente
          if (grupo.get('campos')) {
            this.ocultarLimparCamposFilhos(operacao, passo, grupo, element, isChecked);
          }

        } else if (campo.tipo != 'checkbox') {

          // Se não for checkbox limpa o campo
          this.resetValorField(operacao, passo, grupo, campo);

          // Se for arquivo precisa de um tratamento específico
          if (campo.tipo == 'file') {

            // Retira o arquivo do array para Upload e atualiza os elementos html pertinentes
            let indexFile = this.filesToUpload.findIndex(function (obj) {
              return obj.nome == campo.nome
            });

            if (indexFile != -1) {
              this.filesToUpload.splice(indexFile, 1);
              let elementFileSpan = this.getElementQuerySelector('#files_' + campo.nome + ' span.filename', '');
              elementFileSpan.textContent = ' Nenhum arquivo selecionado.';
            }

          }

        }

        campoControl?.get('Valor')?.clearValidators();
        campoControl?.disable();

      } else {

        const campoValorControl = campoControl?.get('obrigatorio');
        campoControl?.enable();

        /*if(campoValorControl.value){
          campoValorControl.setValidators(Validators.required)
          //campoValorControl.setErrors({'INVALID': true});
        }*/

      }

      campoControl?.get('Valor')?.updateValueAndValidity();

    });

    this.filterJsonFormRequerimento.updateValueAndValidity();

  }

  // Método auxiliar para pegar um elemento html
  getElementQuerySelector(selector: string, nome: string) {
    return this.el.nativeElement.querySelector(selector + nome);
  }

  // Método que limpa o campo
  resetValorField(operacao: any, passo: any, grupo: any, campo: any) {
    this.filterJsonFormRequerimento.controls.stepper.get('operacoes').get(operacao).get('passos')
      .get((passo.stepNumber).toString()).get('grupos').get(grupo.value.nome).get('campos').get(campo.nome).get('valor').reset();
  }

  // Método que pega o Grupo por um campo
  getChamarGrupoByCampo(operacao: any, passo: any, campo: any) {
    return this.filterJsonFormRequerimento.controls.stepper.get('operacoes').get(operacao).get('passos')
      .get((passo.stepNumber).toString()).get('grupos').get(campo.grupo.nome);
  }

  getNomeOperacaoSelecionada() {
    return (!this.operacaoSelecionada || Number(this.operacaoSelecionada) == 1) ? 'Solicitação' : 'Cancelamento';
  }

  /* preencherDadosServidor() {
    this.dadosServidor.nativeElement.click();
  }

  preencherOutrasInformacoes() {
    this.outrasInformacoes.nativeElement.click();
  }

  preencherDocumentos() {
    this.documentos.nativeElement.click();
  } */

  /* preencherCiencias() {
   
    Object.keys(this.formDadosServidor.controls).forEach((key) => {
      //console.log(' key: ' + key + ' value: ' + this.formDadosServidor.get(key).value);
      this.formDadosVisualizacao.get(key.toString()).setValue(this.formDadosServidor.get(key).value);
      this.formDadosVisualizacao.get(key.toString()).disable();
    });

    console.log(["formDadosVisualizacao value"], this.formDadosVisualizacao.value)

    this.ciencias.nativeElement.click();
  } */
  /* 
    preencherConclusao() {
      this.conclusao.nativeElement.click();
    } */

  /* addCamposFormularios(grupos: any) {

    //populando os campos de dados funcionais
    if (this.grupos.length) {
      if (this.filtraGrupoPasso(2).length > 0) {
        for (const grupo of this.filtraGrupoPasso(2)) {
          for (const campo of grupo?.camposGrupos) {
            this.formDadosServidor.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
            this.formDadosVisualizacao.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
          }
        }
      }

      //populando os campos de informacoes
      if (this.filtraGrupoPasso(3).length > 0) {
        for (const grupo of this.filtraGrupoPasso(3)) {
          for (const campo of grupo?.camposGrupos) {
            this.formDadosServidor.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
            this.formDadosVisualizacao.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
          }
        }
      }

      //populando os campos de documentos
      if (this.filtraGrupoPasso(4).length > 0) {
        for (const grupo of this.filtraGrupoPasso(4)) {
          for (const campo of grupo?.camposGrupos) {
            this.formDadosServidor.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
            this.formDadosVisualizacao.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
          }
        }
      }

      //populando os campos de conclusão
      if (this.filtraGrupoPasso(5).length > 0) {
        for (const grupo of this.filtraGrupoPasso(5)) {
          for (const campo of grupo?.camposGrupos) {
            this.formDadosServidor.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
            this.formDadosVisualizacao.addControl(campo.nome, new FormControl('', this.getValidator(campo)));
          }
        }
      }

    }
  }
 */
  /*  pesquisaGruposTiporRequerimento(id: number) {
     this.tipoRequerimentoService.findGruposTipoRequerimento({
       id: id,
     }).subscribe(data => {
       this.tipoRequerimentoSelecionado = data
       this.grupos = this.tipoRequerimentoSelecionado?.grupos
       console.log(["GRUPOS"], this.grupos)
       this.addCamposFormularios(this.grupos);
       this.consultarDadosServidor(this.userResponse);
     });
   } */
}
